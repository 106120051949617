import React from "react";
import styled from "styled-components";

function VaccinePage() {
  return (
    <Wrapper>
      <iframe
        src="https://docs.google.com/presentation/d/e/2PACX-1vRotktJxd9SqYSODEivl5cHsLhW9DwDQHKNOnDax0xtsOCCt6ZBwcPiwKX2Toipxg/embed?start=false&loop=false&delayms=3000&rm=minimal"
        frameBorder="0"
        allowFullScreen
      ></iframe>
    </Wrapper>
  );
}

export default VaccinePage;

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: grid;
  place-content: center;
  background: #000;

  iframe {
    width: 85vw;
    height: 80vh;
  }
`;
